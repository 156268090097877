s<template>
  <div style="position: relative;">
   <div class="export_btn">
    <qzf-video vid="63dd60f588c2cf90a2629239a66135ac_6"></qzf-video>
    <el-button size="small" type="success" @click="daochu()" :disabled="!$buttonStatus('dzsj_sksb_dc')">
      <el-icon><Folder /></el-icon><span  > 导出</span>
    </el-button>
   </div>
  
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="税款申报" name="税款申报"></el-tab-pane>
      <el-tab-pane label="零申报" name="零申报"></el-tab-pane>
      <el-tab-pane label="异常公司" name="异常公司"></el-tab-pane>
      <el-tab-pane label="本期无需申报" name="本期无需申报"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:10px 0px 0px 10px;width:640px" :inline="true" label-width="100px">
            <el-form-item label="税局 ："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员 ：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型 ：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
            </el-form-item>
            <el-form-item label="报税手机号 ："  v-show="activeName != '异常公司' && activeName != '本期无需申报'">          
              <el-input v-model.trim="listQuery.tel" size="small" style="margin-left:5px;width:140px" placeholder="请输入报税手机号"></el-input>
            </el-form-item>
            <el-form-item label="登录状态 ："  v-show="activeName != '异常公司' && activeName != '本期无需申报'">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:140px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="取数状态 ："  v-show="activeName != '异常公司' && activeName != '本期无需申报'">          
              <el-radio-group v-model="listQuery.gsMainStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已取数</el-radio>
                <el-radio :label="2">未取数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="结账状态 ："  v-show="activeName != '异常公司' && activeName != '本期无需申报'">          
              <el-radio-group v-model="listQuery.jzStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结账</el-radio>
                <el-radio :label="2">未结账</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="税款确认状态：" v-if="orgId == 3576" label-width="105px">
              <select-common
                placeholder="请选择确认状态"
                v-model:modelValue="listQuery.ackStatus"
                :options="confirmOption"
                class="w-140"
              />
            </el-form-item>
            <el-form-item label="申报结果："  v-show="activeName != '异常公司' && activeName != '本期无需申报'">
              <selectBussinessStatus v-model:allStatus="listQuery.allStatus"></selectBussinessStatus>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">

        <el-button :disabled="!$buttonStatus('dzsj_sksb_plqs')" :loading="loading" v-if="activeName == '零申报'" size="small" type="primary" @click="plqs()"   >
          <el-icon><List /></el-icon><span  > 批量取数</span>
        </el-button>

        <el-button :disabled="!$buttonStatus('dzsj_sksb_sb')" size="small" type="primary" @click="declaration()"   v-if="activeName != '异常公司' && activeName != '本期无需申报'" :loading="sbLoading">
          <el-icon><List /></el-icon><span  > 批量报税</span>
        </el-button>

        <!-- <el-button :disabled="!$buttonStatus('dzsj_sksb_cxsbrw')" size="small" type="danger" @click="cancelTask()"   v-if="activeName != '异常公司' && activeName != '本期无需申报'" :loading="cxLoading">
          <el-icon><List /></el-icon><span  > 撤销任务</span>
        </el-button> -->
      
      </div>
    </div>
    <div class=" loudou">
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" @filter-change="tableFilterChange" class="date-icon" v-loading="listLoading" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column  label="编号"  align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column  prop="name" fixed label="公司名称" min-width="240">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId">
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column  prop="districtName" label="申报税种" width="250" :filters="this.categories" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="应补（退）税额" min-width="130" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index" >
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>
      
        <el-table-column align="left" prop="districtName" label="取数状态" min-width="110" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-tooltip class="item" effect="dark" v-if="item.gsFailLog" :content="item.gsFailLog" placement="top-start">
                <template #content>
                  <div v-html="item.gsFailLog"></div>
                </template>
                <p style="display:inline-block" v-if="item.gsMainStatus == '1'">已取数 </p>
                <p style="display:inline-block" v-else>未取数</p>
                <el-button @click="checkTableDialog(scope.row,item)" size="small" type="text" :disabled="item.gsMainStatus != '1'">查看</el-button>
              </el-tooltip>
              
              <div v-else>
                <p style="display:inline-block" v-if="item.gsMainStatus == '1'">已取数 </p>
                <p style="display:inline-block" v-else>未取数</p>
                <el-button @click="checkTableDialog(scope.row,item)" size="small" type="text" :disabled="item.gsMainStatus != '1'">查看</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="税款确认状态" width="100" v-if="this.listQuery.accountingType != 3 && orgId == 3576">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$homeConfirmIcon(scope.row.djqr_tax_status)"></i>
              <span>{{ $homeConfirmStatus(scope.row.djqr_tax_status) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报结果" min-width="170" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div class="item_icon">
                <p>
                  <i :class="$batchSendIconSb(item.taskStatus, item.bussinessStatus)"></i>
                  <el-tooltip class="item" effect="dark" :disabled="!item.errLog && !item.bussinessLog" placement="top-start">
                    <template #content>
                      <div v-html="item.bussinessLog?item.bussinessLog:'' + item.errLog?item.errLog:''"></div>
                    </template>
                    <div style="display: inline-block;">
                      <span :class="$batchSendWordSb(item.taskStatus, item.bussinessStatus)">{{$batchSendSb(item.taskStatus,item.bussinessStatus)}}</span>
                      <i class="iconfont icon-wenhao" v-if="(item.bussinessStatus == 3 && item.bussinessLog) || (item.taskStatus == 4 && item.errLog)" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                    </div>
                  </el-tooltip>
                  <span v-if="item.image && item.taskStatus == 3" class="demo-image__preview" style="margin-left: 4px;">
                    <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="item.bussinessStatus == 3" @click="open(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="item.bussinessStatus == 2 ||item.bussinessStatus == 6 ||item.bussinessStatus == 20  || item.bussinessStatus == 22" @click="open(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;" v-if="item.bussinessStatus == 4 || item.bussinessStatus == 5 || item.bussinessStatus == 21 || item.bussinessStatus == 40 || item.bussinessStatus == 60" @click="open(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#e6a23c;font-size:13px;" v-if="item.bussinessStatus == 23" @click="open(item, index,scope.$index)"></i>
                  </span>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="content" label="随手记" min-width="120" v-if="this.listQuery.accountingType != 3 && $buttonStatus('jzkh_ssj')" show-overflow-tooltip>
          <template #default="scope">
            <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
            <span>{{scope.row.jotDown}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="this.listQuery.accountingType == 3" label="最后结账账期" align="center" min-width="220">
          <template #default="scope">
            {{scope.row.curPeriod}}
          </template>
        </el-table-column>
        <el-table-column v-if="this.listQuery.accountingType == 3 && activeName == '本期无需申报'" label="操作" align="center" min-width="180">
          <template #default="scope">
            <el-button @click="checkCate(scope.row)" size="small" type="primary" plain>查看税种</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-button :disabled="!$buttonStatus('dzsj_sksb_sb') || item.gsMainStatus != '1' ||(orgId == 3576 && ![1,3].includes(scope.row.djqr_tax_status))" @click="handleUpdate(item,scope.row)" size="small" type="text">申报</el-button>
              <el-button @click="declareDetail(scope.row,item)" size="small" type="text" :disabled="item.taskStatus == 0 ">申报记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="batchSendSz"/>
    </div>
        </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <checkTbaleDialogs ref="checkTbaleDialogs"></checkTbaleDialogs>
  <div
    id="message"
    class="el-message el-message--warning"
    style="top:16px;z-index:2087;display: none;align-items: normal;"
  >
    <el-icon style="color: #e6a23c;margin-right: 4px;">
      <warning-filled />
    </el-icon>

    <p class="el-message__content" style="cursor: pointer;">
      温馨提示:有{{notOnList}}家企业未在申报列表：{{notOnCurrentPeriod}}家未结账到本账期（<a  @click="switchTab('1')">点击查看</a>）
      <p style="margin-top: 3px;margin-left: 238px;" v-if="noTax">{{ noTax }}家没有税表（<a @click="switchTab('2')">点击查看</a>）</p>
    </p>
    <el-icon style="margin-left: 10px;color: #999;cursor: pointer;" @click="close">
      <close />
    </el-icon>
  </div>
  <noTaxCategory ref="noTaxCategory" />
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
  <sbImage ref="sbImage" />
</template>

<script>
import { companySendTaxList,companyCarryOverList,exCompanyCarryOver } from "@/api/company"
import { declarationModuleExport } from '@/api/export'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask, quitTask } from "@/api/collection"
import { zeroDeclaration } from "@/api/taxCalculation"
import selectBussinessStatus from "./select/selectBussinessStatus.vue"
import checkTbaleDialogs from "./checkTbaleDialog.vue";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import { getUnclosed,getNoTax,getNoTaxExport } from "@/api/carryover";
import noTaxCategory from './noTaxCategory.vue'
import jotDown from '@/components/jotDown'
import sbImage from '@/components/sbImage'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION,HOME_CONFIRM_STATUS } from '@/utils/commonData.js'


export default {
  name: "batchSendSz",
  components:{
    selecttaxtype,
    declareDetails,
    selectBussinessStatus,
    checkTbaleDialogs,
    selectcity,
    selectAccreditStatus,
    noTaxCategory,
    jotDown,
    sbImage,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      orgId: this.$store.getters["user/user"].orgId,
      typeOptions: COMPANY_TYPE_OPTION,
      loading: false,
      listLoading:false,
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        jzStatus: 0,
        taskName:'tax-sb-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        accountingType: 2,
        taxNames:[],
        businessStatus:[],
        allStatus: [],
        companyType:''
      },
      total:0,
      list:[],
      gsMainIds:[],
      comIds: [],
      pageStatus: true,
      reqNos: [],
      gsMainIdsStatus:[],
      categories: [
        {text: '财务报表', value: 'gs_cwbb'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '其他收入(工会经费)', value: 'gs_qtsr'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
        {text: '个人所得税(定期定额)', value: 'gs_tax_dqde'},
      ],
      noteContent:'',
      noTax: 0,
      notOnList:0,
      notOnCurrentPeriod:0,
      sbLoading:false,
      cxLoading:false,
      selects:[],
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false,
      confirmOption:HOME_CONFIRM_STATUS
    }
  },
  created(){
    this.listQuery.limit = localStorage.getItem('batchSendSz') ? localStorage.getItem('batchSendSz')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(251)
    this.initBus() 
  },
  mounted(){
    this.init()
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  methods:{
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      let originAccountingType = this.listQuery.accountingType
      this.listQuery={
        page: 1,
        limit: originLimit,
        taskName:'tax-sb-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        accountingType: originAccountingType,
        taxNames:[],
        businessStatus:[],
        allStatus: [],
        districtCode:null,
        userId:null,
        type:null,
        tel:'',
        keepStatus:null,
        gsMainStatus:null,
        jzStatus:null,
        companyType:''
      }
      this.getList()
   },
    init(){
      let box = document.getElementById("message")
      getUnclosed({}).then((res) => {
        if (res.data.msg == "success" && res.data.data.notOnList) {
          box.style.display = 'flex';
          this.noTax = res.data.data.noTax;
          this.notOnList = res.data.data.notOnList;
          this.notOnCurrentPeriod = res.data.data.notOnCurrentPeriod;
        }
      });
      setTimeout(()=>{
        box.style.display = 'none';
      },5000)
    },
    close(){
      document.getElementById("message").style.display = 'none';
    },
    cancelTask(){
      this.$confirm('确定撤销申报任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.cxLoading = true
        quitTask({reqNos:this.reqNos}).then(res => {
          this.cxLoading = false
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(this.activeName == '异常公司' || this.activeName == '本期无需申报'){
          return
        }
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if ([1,2,99].includes(item.taskStatus)) {
              status = true;
            }
          })
        })
        if(status == true) {
          companySendTaxList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    getList(){
      this.listLoading = true
      if(this.activeName == '异常公司'){
        companyCarryOverList(this.listQuery).then(res=>{
          this.listLoading = false
          this.tableData = res.data.data.list? res.data.data.list : []
          this.total = res.data.data.total
          this.setScroll()
        })
      }else if(this.activeName == '本期无需申报'){
        getNoTax(this.listQuery).then(res=>{
          this.listLoading = false
          this.tableData = res.data.data.notax ? res.data.data.notax : []
          this.total = res.data.data.total
          this.setScroll()
        })
      }else{
        companySendTaxList(this.listQuery).then(res=>{
          this.listLoading = false
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.setScroll()
        })
      }
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      this.reqNos = []
      this.comIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleClick(tab) {
      this.activeName = tab.props.name
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
      }else if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
      }else{
        this.listQuery.accountingType = 3
      }
      this.getList()
    },
    switchTab(flag){
      if(flag == '1'){
        this.activeName = "异常公司"
        this.listQuery.accountingType = 3
      }else{
        this.listQuery.accountingType = 3
        this.activeName = "本期无需申报"
      }
      this.getList()
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    getIds(){
      this.gsMainIds = []
      this.reqNos = []
      this.comIds = []
      this.gsMainIdsStatus = []
      if(this.activeName == '异常公司' || this.activeName == '本期无需申报'){
        this.selects.map(info=>{
          this.comIds.push(info.comId)
        })
      }else{
        this.selects.map(info=>{
          this.comIds.push(info.comId)
          info.items.map(item=>{
            this.gsMainIdsStatus.push({gsMainId: item.gsMainId, gsMainStatus: item.gsMainStatus})
            this.gsMainIds.push(item.gsMainId)
            this.reqNos.push(item.reqNo)
          })
        })
      }
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.reqNos = []
        this.comIds = []
        this.allChecked = false
      }
    },
    // 申报详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    plqs(){
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        period: this.listQuery.period,
        comIds: this.comIds
      }
      this.loading = true
      zeroDeclaration(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          if(res.data.data){
            this.$qzfMessage('请稍后刷新查看',3)
          } else {
            this.$qzfMessage('成功')
          }
          this.getList()
        }
      })
    },
    //批量申报
    declaration() {
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      //顶呱呱过滤确税状态
      if(this.orgId == 3576){
        let fail = this.selects.find(v=> ![1,3].includes(v.djqr_tax_status))
        if(fail){
          this.$qzfMessage(`${fail.name}未确认，请先确认！`,1)
          return
        }
      }
      //过滤登录状态
      // let str = ''
      // this.selects.map(v=>{
      //   if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
      //     str = v.name + '未授权登录，请先授权再发起任务！'
      //     return
      //   }
      // })
      // if(str){
      //   this.$qzfMessage(str,1)
      //   return
      // }
      let status = false
      this.gsMainIdsStatus.map(v=>{
        if(v.gsMainStatus != 1){
          status = true
          return
        }
      })
      if(status){
        this.$qzfMessage("请先取数再进行申报", 1)
        return
      }
      this.sbLoading = true
      let param = {
        taskName: 'tax_sb',
        gsMainIds: this.gsMainIds
      }
      sendTask(param).then(res => {
        this.sbLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //申报
    handleUpdate(e,row) {
      // if(row.district != 'zhejiang' && row.district != 'guangdong' && row.district != 'tianjin' && row.district != 'hubei' && row.loginMethod == '新版登录' && row.yzmType == 1 && row.keepStatus != 1){
      //   this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
      //   return
      // }
      this.$confirm('确定要申报吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        let param = {
          taskName: 'tax_sb',
          gsMainIds: [e.gsMainId]
        }
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    open(row, index,bigIndex) {
      let imageAll = []
      let currentIndex = index //当前渲染的图片
      this.list.map((obj1,i1)=>{
        let arr = []
        obj1.items.map((obj2,i2)=>{
          if(obj2.image.match(',')){
            let imgUrls = obj2.image.split(',')
            imgUrls = imgUrls.filter(v=>{return v})
            imgUrls.map((obj3,i3)=>{
              if(obj3){
                arr.push({
                  img:'https://file.listensoft.net' + obj3 + "?" + Math.random(10),
                  tableName:obj2.tableName
                })
                if(i2 < index && i1 == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(obj2.image){
              arr.push({
                img:'https://file.listensoft.net' + obj2.image + "?" + Math.random(10),
                tableName:obj2.tableName
              })
            }else{
              if(i2<index && i1 == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:obj1.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
    // 查看报表
    checkTableDialog(row,item){
      let param = {
        comId:row.comId,
        qysdssType:row.qysdsStatus,
        period:this.listQuery.period,
        tableName:item.tableName,
        ComKj:row.ComKj,
        comName:row.name,
      }
      this.$refs.checkTbaleDialogs.openDialog(param)
    },
    //导出
    daochu(){
      if(this.activeName == '异常公司'){
        let param = {
          comIds:this.comIds,
          info:this.listQuery
        }
        exCompanyCarryOver(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }else if(this.activeName == '本期无需申报'){
        let param = {
          comIds:this.comIds,
          info:this.listQuery
        }
        getNoTaxExport(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }else{
        let param = {
          query:this.listQuery,
          comIds:this.comIds
        }
        declarationModuleExport(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    checkCate(row){
      this.$refs.noTaxCategory.init(row)
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.item_icon{
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
.el-table {
    th {
    padding: 0px;
    // padding-left: 10px;
  }
    td {
    padding: 0px;
  }
}
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.export_btn{
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
:deep(textarea){
  min-height: 145px !important;
}
.el-message__content a{
  text-decoration: underline;
  color: #409eff;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}

</style>
<style>
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>