<template>
  <div>
    <div class="top_data">
      <div>
        <span class="data_title">税款：</span>未确认<span class="data_count">{{
          wqrCount
        }}</span>
        已确认，可扣款<span class="data_count">{{ qrCount }}</span>
        已确认，暂不扣款<span class="data_count">{{ qr6Count }}</span>
      </div>
    </div>
    <div class="content_select">
      <div class="left_select">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span
            style="margin-top: 2px;">刷新</span></el-button>
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" class="w-160" size="small" @keyup.enter="getList"
          clearable /><el-button type="primary" @click="getList" size="small" class="mr-5" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width: 420px" label-width="120px" size="small">
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" class="w-140"></selectcity>
            </el-form-item>
            <el-form-item label="公司类型：">
              <el-select v-model="listQuery.companyType" placeholder="请选择公司类型" filterable clearable class="w-140">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="随手记 ：">
              <el-input v-model="listQuery.jotDown" style="margin-left: 5px;" class="w-140" size="small"
                placeholder="请输入随手记" />
            </el-form-item>
            <el-form-item label="确认状态：">
              <select-common placeholder="请选择确认状态" v-model:modelValue="listQuery.ackStatus" :options="confirmOption"
                class="w-140" />
            </el-form-item>
            <el-form-item label="确认人：">
              <input-common v-model:modelValue="listQuery.ackTaxName" placeholder="请输入确认人"
                class="w-140 ml-5"></input-common>
            </el-form-item>
            <el-form-item label="确认时间：">
              <el-date-picker style="width: 100px;margin-left: 5px;" size="small" v-model="listQuery.ackTimeSt"
                type="date" placeholder="开始时间" value-format="YYYY-MM-DD" />
              <span style="margin: 0 2px;">-</span>
              <el-date-picker style="width: 100px" size="small" v-model="listQuery.ackTimeEd" type="date"
                placeholder="结束时间" value-format="YYYY-MM-DD" />
            </el-form-item>
            <el-form-item label="税款超时待确认：">
              <el-input v-model="listQuery.overTimeSt" style="width: 100px;margin-left: 5px;"
                @change="(e) => { listQuery.overTimeSt = e ? Number(e) : null }"></el-input>
              <span style="margin: 0 2px;">-</span>
              <el-input v-model="listQuery.overTimeEd" style="width:100px"
                @change="(e) => { listQuery.overTimeEd = e ? Number(e) : null }"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.not0" class="ml-5" size="small" @change="getList">
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">有税款</el-radio-button>
          <el-radio-button :label="2">无税款</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button @click="confirmAll(6)" type="primary" size="small" plain icon="Checked">已确认，暂不扣款
        </el-button>
        <el-button @click="confirmAll(1)" type="primary" size="small" plain icon="Checked">已确认，可扣款
        </el-button>
        <el-button type="primary" size="small" @click="copyTax" icon="DocumentCopy">复制税款
        </el-button>
        <el-button type="primary" size="small" @click="adjustTax" icon="EditPen" plain>调整税款
        </el-button>
        <el-button @click="daochu" type="primary" size="small" icon="FolderOpened">
          导出
        </el-button>
      </div>
    </div>
    <div>
      <el-table :data="tableData" border style="width: 100%" v-loading="loading" :height="contentStyleObj"
        @selection-change="handleSelectionChange" stripe @sort-change="sortChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" min-width="280" fixed="left">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false" />
          </template>
        </el-table-column>
        <el-table-column prop="districtName" label="税局" width="65" align="center">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row?.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="总税金" sortable align="right" min-width="90" />
        <el-table-column prop="zzs" label="增值税" sortable align="right" min-width="90" />
        <el-table-column prop="fjs" label="附加税" sortable align="right" min-width="90" />
        <el-table-column prop="deed" label="行为税" sortable align="right" min-width="90" />
        <el-table-column prop="gs" label="个税" sortable align="right" min-width="90" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row?.gs }}
            <span v-if="scope.row?.gsFjs && scope.row?.gsFjs != '0' && scope.row?.gsFjs != '0.00'">+{{ scope.row?.gsFjs
              }}</span>
            <span v-if="scope.row?.gsOnly && scope.row?.gsOnly != '0' && scope.row?.gsOnly != '0.00'">+{{
              scope.row?.gsOnly
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qysds" label="企业所得税" sortable align="right" min-width="115" />
        <el-table-column prop="dqde" label="定期定额" sortable align="right" min-width="95" />
        <el-table-column prop="qt" label="其他税种合计" align="left" min-width="140">
          <template #default="scope">
            <div class="item_icon" style="justify-content: space-between">
              <div>
                {{
                  (
                    Number(scope.row?.scjysds) +
                    Number(scope.row?.whsyjss) +
                    Number(scope.row?.ghjf) +
                    Number(scope.row?.ljf) +
                    Number(scope.row?.xfs) +
                    Number(scope.row?.cbj) +
                    Number(scope.row?.sl) +
                    Number(scope.row?.hbs) +
                    Number(scope.row?.qt)
                  ).toFixed(2)
                }}
              </div>
              <el-button @click="checkDetail(scope.row)" size="small" link>详情</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="随手记" min-width="120" show-overflow-tooltip fixed="right">
          <template #default="scope">
            <span class="xgmStyle" style="cursor: pointer" @click="smallNotes(scope.row)">记</span>
            <span>{{ scope.row?.jotDown }}</span>
          </template>
        </el-table-column>
        <el-table-column label="确认状态" width="135" fixed="right">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$homeConfirmIcon(scope.row?.djqr_tax_status)"></i>
              <span>{{ $homeTaxStatus(scope.row?.djqr_tax_status) }}</span>
              <n-badge :value="$filterHomeBadge(scope.row?.jzTime)" show-zero :max="9"
                :color="$filterHomeBadge(scope.row?.jzTime, 'color')"
                v-if="scope.row?.djqr_tax_status == 4 && !(scope.row?.jzTime).match('0001')">
              </n-badge>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="推税时间" width="110" fixed="right">
          <template #default="scope">
            <span v-if="!(scope.row?.jzTime).match('0001')">{{
              $parseTime(scope.row?.jzTime, '{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="确认时间" width="150" fixed="right">
          <template #default="scope">
            <span
              v-if="!scope.row?.djqr_tax_time?.match('0001') && (scope.row?.djqr_tax_status == 1 || scope.row?.djqr_tax_status == 6)">{{
                $parseTime(scope.row?.djqr_tax_time, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="确认人" width="90" fixed="right">
          <template #default="scope">
            <div v-if="[1, 3].includes(scope.row?.djqr_tax_status)">
              {{
                scope.row?.djqr_tax_status == 1
                  ? scope.row?.djqr_tax_name
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>
  </div>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisible" title="税款详情" width="500px">
    <table class="content content_head" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td style="width: 60%">税种</td>
        <td style="width: 40%">税额</td>
      </tr>
      <tr>
        <td>生产经营所得税</td>
        <td>{{ taxInfo.scjysds }}</td>
      </tr>
      <tr>
        <td>文化事业建设费</td>
        <td>{{ taxInfo.scjysds }}</td>
      </tr>
      <tr>
        <td>工会经费</td>
        <td>{{ taxInfo.ghjf }}</td>
      </tr>
      <tr>
        <td>垃圾费</td>
        <td>{{ taxInfo.ljf }}</td>
      </tr>
      <tr>
        <td>消费税</td>
        <td>{{ taxInfo.xfs }}</td>
      </tr>
      <tr>
        <td>残保金</td>
        <td>{{ taxInfo.cbj }}</td>
      </tr>
      <tr>
        <td>水利基金</td>
        <td>{{ taxInfo.sl }}</td>
      </tr>
      <tr>
        <td>环保税</td>
        <td>{{ taxInfo.hbs }}</td>
      </tr>
      <tr>
        <td>其他税种</td>
        <td>{{ taxInfo.qt }}</td>
      </tr>
    </table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <jotDown ref="jotDownRef" @jotDownUpdate="updateNote" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import { ackCompany } from "@/api/home";
import { companyTaxAmountList } from "@/api/company";
import {
  COMPANY_TYPE_OPTION,
  TAX_CONFIRM_STATUS
} from "@/utils/commonData.js";
import selectcity from "@/components/Screening/selectcity";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import { wbVoucherFxOther } from "@/api/export";
import jotDown from "@/components/jotDown";
const { proxy } = getCurrentInstance();
const props = defineProps({
  period: { type: String, default: "" },
  changeHeight: { type: Boolean, default: false },
  taxType: { type: String, default: "" },
  userId: { type: Array, default: () => [] },
});
const listQuery = ref({
  page: 1,
  limit: 20,
  name: "",
  period: props.period,
  type: "",
  not0: 0,
  autoMatic: 2,
  jotDown: '',
});
const tableData = ref([]);
const total = ref(0);
const dialogVisible = ref(false);
const loading = ref(false);
const contentStyleObj = ref();
const selects = ref([]);
const taxInfo = ref({});
const userName = proxy.$store.getters["user/user"].cnName;
const qrCount = ref(0);
const wqrCount = ref(0);
const qr6Count = ref(0)
const typeOptions = COMPANY_TYPE_OPTION;
const confirmOption = TAX_CONFIRM_STATUS;
const jotDownRef = ref();
// 监听 period 变化
watch(
  () => [props.period, props.taxType, props.userId],
  (newVal) => {
    if (proxy.$route.query.status) {
      listQuery.value.ackStatus = proxy.$route.query.status * 1
    }
    listQuery.value.period = newVal[0];
    listQuery.value.type = newVal[1];
    listQuery.value.userId = newVal[2];
    getList();
  },
  { immediate: true }
);
watch(
  () => props.changeHeight,
  (newVal) => {
    if (newVal) {
      contentStyleObj.value = proxy.$getHeight(490);
    } else {
      contentStyleObj.value = proxy.$getHeight(350);
    }
  }
);
onMounted(() => {
  contentStyleObj.value = props.changeHeight
    ? proxy.$getHeight(490)
    : proxy.$getHeight(350);
  if (proxy.$route.query.status) {
    listQuery.value.ackStatus = proxy.$route.query.status * 1
    getList();
  }
});
function getList() {
  loading.value = true;
  companyTaxAmountList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      tableData.value = res.data.data.list;
      total.value = res.data.data.total;
      qrCount.value = res.data.data.sureTotal;
      wqrCount.value = res.data.data.noSureTotal;
      qr6Count.value = res.data.data.sure6Total;
    }
  });
}
const handleSelectionChange = (val) => {
  selects.value = val;
};
//确认税款
const confirmTax = (row) => {
  proxy
    .$confirm("是否确认税款？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let param = {
        comIds: [row.comId],
        period: listQuery.value.period,
        type: "tax",
        status: 1,
        errLog: "",
        name: userName,
      };
      ackCompany(param).then((res) => {
        if (res.data.msg == "success") {
          proxy.$qzfMessage("确认成功");
          getList();
        }
      });
    });
};
//批量确认
const confirmAll = (flag) => {
  if (selects.value.length == 0) {
    proxy.$qzfMessage("请选择公司", 1);
    return;
  }
  let fail = selects.value.find((v) => ![0, 4, 2, 6].includes(v.djqr_tax_status));
  if (fail) {
    proxy.$qzfMessage(`${fail.name}不可确认请重新选择`, 1);
    return;
  }
  let text = flag == 6 ? '已确认，暂不扣款' : '已确认，可扣款'
  proxy
    .$confirm(`确定要批量修改为【${text}】吗？`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let param = {
        comIds: selects.value.map((v) => {
          return v.comId;
        }),
        period: listQuery.value.period,
        type: "tax",
        status: flag,
        errLog: "",
        name: userName,
      };
      ackCompany(param).then((res) => {
        if (res.data.msg == "success") {
          proxy.$qzfMessage("操作成功");
          getList();
        }
      });
    });
};
//调整税款
const adjustTax = () => {
  if (selects.value.length == 0) {
    proxy.$qzfMessage("请选择公司", 1);
    return;
  }
  // let fail = selects.value.find((v) => v.djqr_tax_status != 0);
  // if (fail) {
  //   proxy.$qzfMessage(`${fail.name}不可调整税款请重新选择`, 1);
  //   return;
  // }
  proxy.$prompt("", "随手记-调整税款说明", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputValue: "",
    inputType: "textarea",
    inputPlaceholder: "请输入调整税款原因",
    customClass: "prompt_style",
    beforeClose: (action, instance, done) => {
      if (action == "confirm") {
        let value = instance.inputValue;
        if (!value) {
          proxy.$qzfMessage("请输入调整税款原因", 1);
        } else {
          let param = {
            comIds: selects.value.map((v) => {
              return v.comId;
            }),
            period: listQuery.value.period,
            type: "tax",
            status: 2,
            errLog: value,
            name: userName,
          };
          ackCompany(param).then((res) => {
            done();
            if (res.data.msg == "success") {
              proxy.$qzfMessage("已驳回");
              getList();
            }
          });
        }
      } else {
        done();
      }
    },
  });
};
const smallNotes = (row) => {
  jotDownRef.value.init(row.name, row.comId, props.period);
};
const updateNote = (id, text) => {
  tableData.value.map((v) => {
    if (v.comId == id) {
      v.jotDown = text;
    }
  });
};
//复制税款
const copyTax = () => {
  if (selects.value.length == 0) {
    proxy.$qzfMessage("请选择公司", 1);
    return;
  }
  // 基础字符串数组
  let rows = [];
  // 使用对象键值对来简化税种的判断和拼接
  const taxes = {
    zzs: "增值税",
    fjs: "附加税",
    deed: "行为税",
    gs: "个税",
    qysds: "企业所得税",
    sb: "社保",
    dqde: "定期定额",
    scjysds: "生产经营所得税",
    whsyjss: "文化事业建设费",
    ghjf: "工会经费",
    ljf: "垃圾费",
    xfs: "消费税",
    cbj: "残保金",
    sl: "水利基金",
    hbs: "环保税",
    gsOnly: "全年一次性奖金",
    gsFjs: "劳务报酬"
  };
  // 遍历selects数组
  selects.value.forEach((v) => {
    let row = `您的企业「${v.name}」${props.period}账期`;
    if (v.total && v.total !== "0.00") {
      row += "税款如下：";
      Object.keys(taxes).forEach((key) => {
        const taxName = taxes[key];
        const taxValue = v[key];
        if (
          taxValue &&
          taxValue !== "0" &&
          taxValue !== "0.00" &&
          taxName != "社保"
        ) {
          row += `\r${taxName}：${taxValue}`;
        }
      });
      row += `\r总计：${v.total}`;
    } else {
      row += "暂无税款";
    }
    rows.push(row);
  });
  // 将所有行拼接成最终字符串，注意最后的提示信息
  let str =
    rows.join("\r") +
    `\r${props.period}账期税款如上，请确认，如有疑问请随时沟通`;
  proxy.$copyComName(str);
  let param = {
    comIds: selects.value.map((v) => {
      return v.comId;
    }),
    period: listQuery.value.period,
    type: "tax",
    status: 4,
    errLog: "",
    name: userName,
  };
  ackCompany(param).then((res) => {
    if (res.data.msg == "success") {
      getList();
    }
  });
};
const checkDetail = (row) => {
  taxInfo.value = row;
  dialogVisible.value = true;
};
//导出
const daochu = () => {
  let param = {
    outType: "excel",
    exportType: "company_tax_list",
    beginPeriod: listQuery.value.period,
    endPeriod: listQuery.value.period,
    query: {
      comIds: selects.value.map((v) => {
        return v.comId;
      }),
      endPeriod: listQuery.value.period,
      beginPeriod: listQuery.value.period,
      not0: listQuery.value.not0,
      name: listQuery.value.name,
      districtCode: listQuery.value.districtCode,
      companyType: listQuery.value.companyType,
      ackStatus: listQuery.value.ackStatus,
      autoMatic: 2,
      period: listQuery.value.period,
      type: listQuery.value.taxType,
      userId: listQuery.value.userId,
    },
  };
  if (selects.value.length != 0) {
    wbVoucherFxOther(param).then((res) => {
      if (res.data.msg == "success") {
        window.open(res.data.data.url);
      }
    });
  } else {
    param.query.comIds = [];
    proxy
      .$confirm("是否全部导出", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
      .then(() => {
        wbVoucherFxOther(param).then((res) => {
          if (res.data.msg == "success") {
            window.open(res.data.data.url);
          }
        });
      });
  }
};
// 表格排序
const sortChange = (data) => {
  if (data.column.label == "编号") {
    if (data.order == "descending") {
      listQuery.value.desc = 1;
    } else {
      listQuery.value.desc = 0;
    }
    getList();
  }
};
const cancel = () => {
  const originLimit = listQuery.value.limit;
  listQuery.value = {
    page: 1,
    limit: originLimit,
    name: "",
    period: props.period,
    type: props.taxType,
    not0: 0,
    autoMatic: 2,
    userId: props.userId,
    jotDown: '',
  };
  getList();
};
</script>

<style scoped lang="scss">
.top_data {
  width: 100%;
  background-color: #edf8fa;
  padding: 10px;
  display: flex;
  align-items: center;

  div {
    width: 33%;
    text-align: center;
  }

  .data_count {
    color: #17a2b8;
    margin: 0 5px;
  }

  .data_title {
    margin-right: 20px;
    position: relative;
  }

  .data_title::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--themeColor, #17a2b8);
  }
}

.content_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .left_select {
    display: flex;
    align-items: center;
  }
}

.item_icon {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;

  i {
    font-size: 13px;
    margin-right: 2px;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;

  tr {
    padding: 0;
    margin: 0;
  }

  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 25px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
    text-align: center;
  }
}
</style>
<style lang="scss">
.prompt_style {
  textarea {
    min-height: 100px !important;
  }
}
</style>
