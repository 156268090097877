<template>
  <div :style="{ height: contentStyleObj }">
  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="税种申报" name="1" :cache="false">
      <batchSendSz ref="batchSendSb" :activeName = "activeName"></batchSendSz>
    </qzf-tabs-item>
    <qzf-tabs-item label="税种缴款" name="2" :cache="false">
      <taxPaySz></taxPaySz>
    </qzf-tabs-item>
    <qzf-tabs-item label="完税证明" name="3" :cache="false">
        <taxCheckProve ref="batchSendSb" :activeName = "activeName"></taxCheckProve>
      </qzf-tabs-item>
  </qzf-tabs>
 
  </div>
</template>

<script>
import batchSendSz from "./components/batchSendSz"
import taxPaySz from "./components/taxPaySz"
import taxCheckProve from "./components/taxCheckProve"


export default {
  name:'batch-send-tax',
  components:{
    batchSendSz,
    taxPaySz,
    taxCheckProve,
},
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init'
      },
      contentStyleObj: {}, //高度变化
    }
  },
  created(){
    this.contentStyleObj = this.$getHeight(190);
  },
}
</script>

<style lang="scss" scoped>

</style>