<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    :title="comInfo.name + '-税种信息'"
    v-model="visible"
    width="1000px"
  >
    <el-table :data="list" border height="500px" style="width:100%" v-loading="loading">
      <el-table-column label="序号" align="center" width="55" type="index">
      </el-table-column>
      <el-table-column label="征收项目" align="center" min-width="160">
        <template #default="{row}">
          <span>{{ row.collectionProject }}</span>
        </template>
      </el-table-column>
      <el-table-column label="征收品目" align="center" min-width="160">
        <template #default="{row}">
          <span>{{ row.collectionItems }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申报周期" align="center" min-width="100">
        <template #default="{row}">
          {{ row.type }}
        </template>
      </el-table-column>
      <el-table-column label="征收方式" align="center" min-width="100">
        <template #default="{row}">
          <span>{{ row.collectionAgency }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用时间" align="center" min-width="120">
        <template #default="{row}">
          {{ row.validityPeriodStart }}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center" min-width="120">
        <template #default="{row}">
          {{ row.validityPeriodEnd }}
        </template>
      </el-table-column>
      <el-table-column label="税率（小数）" align="center" min-width="90">
        <template #default="{row}">
          {{ row.rate }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { taxCategoryList } from "@/api/taxCalculation.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      comInfo: {},
      loading: false,
      list: [],
    };
  },
  methods: {
    init(row) {
      this.comInfo = row;
      this.visible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      taxCategoryList({ comId: this.comInfo.comId }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
