<template>
  <div class="totalStyle">扣款统计</div>
  <div
    id="chargeback"
    v-if="show"
    class="home_echarts"
    style="width: 34%; height: 100%"
  ></div>
  <div v-else class="home_echarts"></div>
  <div class="home_content">
    <div class="home_content_text">
      <ul>
        <li>未开始</li>
        <li>进行中</li>
        <li>已完成</li>
      </ul>
    </div>
    <div class="home_progress">
      <div class="home_progress_each">
        <el-progress :text-inside="true" :stroke-width="12" :percentage="wksPer" style="width: 70%" status="warning"
          color="#afacac">
          <span>{{ list.wks }}家</span>
        </el-progress><span style="font-size: 11px">{{ wksPer }}%</span>
      </div>
      <div class="home_progress_each">
        <el-progress :text-inside="true" :stroke-width="12" :percentage="jxzPer" style="width: 70%" status="info"
          color="#409eff">
          <span>{{ list.jxz }}家</span></el-progress><span style="font-size: 11px">{{ jxzPer }}%</span>
      </div>
      <div class="home_progress_each">
        <el-progress :text-inside="true" :stroke-width="12" :percentage="ywcPer" style="width: 70%" status="success"
          color="#67c23a">
          <span>{{ list.ywc }}家</span></el-progress><span style="font-size: 11px">{{ ywcPer }}%</span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";
export default {
  name: "chargebackTotal",
  props: {},
  data() {
    return {
      show: false,
      wksPer: 0,
      jxzPer: 0,
      ywcPer: 0,
      list: {
        wks: 0,
        jxz: 0,
        ywc: 0
      },
      allTotal: 0,
    };
  },
  methods: {
 
    init(list) {
      this.list = list;
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    initChart(list) {
      this.show = false;
      let that = this;
      let wks = list.wks;
      let jxz = list.jxz;
      let ywc = list.ywc;
      this.allTotal = wks + jxz + ywc;
      this.wksPer = wks == 0 ? 0 : ((wks / this.allTotal) * 100).toFixed(0);
      this.jxzPer = jxz == 0 ? 0 : ((jxz / this.allTotal) * 100).toFixed(0);
      this.ywcPer = ywc == 0 ? 0 : ((ywc / this.allTotal) * 100).toFixed(0);
      setTimeout(() => {
        that.show = true;
        that.$nextTick(() => {
          var myChart = echarts.init(document.getElementById("chargeback"));
          let isGraphicVisible = true; // 控制 graphic 显示状态的变量
          myChart.setOption({
            title: {
              // text: '扣款统计',
              left: "center",
              padding: 10,
              textStyle: {
                fontSize: "13",
                fontWeight: "normal",
              },
            },
            tooltip: {
              trigger: "none",
            },
            color: ["#afacac", "#409eff", "#67c23a"],
            series: [
              {
                type: "pie",
                radius: ["80%", "55%"],
                label: {
                  show: false,
                  position: "center",
                  formatter: "{b}:{c}" + "\n\r" + "({d}%)",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "12",
                  },
                },
                labelLine: {
                  show: true,
                },
                data: [
                  { value: wks, name: "未开始" },
                  { value: jxz, name: "进行中" },
                  { value: ywc, name: "已完成" },
                ],
              },
            ],
            graphic: {
              type: "text",
              left: "center",
              top: "center",
              style: {
                text: "总数:\n\n" + that.allTotal + "家", // 显示总数
                textAlign: "center",
                textVerticalAlign: "middle",
                fontSize: 9, // 根据需要调整字体大小
                fontColor: "#333",
                fontWeight: "500", // 根据需要调整字体样式
                opacity: isGraphicVisible ? 1 : 0, // 根据变量设置透明度
              },
            },
          });
          myChart.on("mouseover", { seriesType: "pie" }, () => {
            isGraphicVisible = false; // 鼠标移入时隐藏 graphic
            myChart.setOption({
              graphic: {
                style: {
                  opacity: 0, // 更新 graphic 的透明度
                },
              },
            });
          });

          myChart.on("mouseout", { seriesType: "pie" }, () => {
            isGraphicVisible = true; // 鼠标移出时显示 graphic
            myChart.setOption({
              graphic: {
                style: {
                  opacity: 1, // 更新 graphic 的透明度
                },
              },
            });
          });
          window.onresize = function () {
            myChart.resize();
          };
          myChart.on("click", (params) => {
            // params.name 包含了被 hover 的扇区的名称
            // 根据名称调用不同的方法
            switch (params.name) {
              case "未开始":
                this.$emit("refresh-chargebackTotal", "wks");
                break;
              case "已完成":
                this.$emit("refresh-chargebackTotal", "ywc");
                break;
              // ... 其他情况 ...
              default:
                this.$emit("refresh-chargebackTotal", "jxz");
            }
          });
        });
      }, 500);
    },
  },
};
</script>
