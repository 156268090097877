<template>
  <div class="none-borders">
    <div class="top_data" v-if="checkType == 'book'">
      <div>
        <span class="data_title">结账状态：</span>
        未结账<span class="data_count">{{ jzTotal?.wks }}</span> 已结账<span
          class="data_count"
          >{{ jzTotal?.ywc }}</span
        >
      </div>
    </div>
    <div class="top_data" v-else>
      <div>
        <span class="data_title">申报：</span>
        未申报<span class="data_count">{{ sbTotal?.wks }}</span> 进行中<span
          class="data_count"
          >{{ sbTotal?.jxz }}</span
        >
        已完成<span class="data_count">{{ sbTotal?.ywc }}</span>
      </div>
      <div>
        <span class="data_title">扣款：</span>
        未扣款<span class="data_count">{{ kkTotal?.wks }}</span> 进行中<span
          class="data_count"
          >{{ kkTotal?.jxz }}</span
        >
        已完成<span class="data_count">{{ kkTotal?.ywc }}</span>
      </div>
      <div>
        <span class="data_title">检查：</span>
        未检查<span class="data_count">{{ jcTotal?.wks }}</span> 进行中<span
          class="data_count"
          >{{ jcTotal?.jxz }}</span
        >
        已完成<span class="data_count">{{ jcTotal?.ywc }}</span>
      </div>
    </div>
    <div>
      <div class="mb-10">
        <el-button
          icon="RefreshRight"
          size="small"
          @click="getList()"
          class="mr-5"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入公司名称"
          v-model.trim="listQuery.name"
          class="w-160"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          class="mr-10"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin: 11px 0px 0px 15px; width: 460px"
            label-width="100px"
            size="small"
          >
            <el-form-item label="税局 ：">
              <addressList
                v-model:type="listQuery.address"
                class="w-140"
              ></addressList>
            </el-form-item>
            <el-form-item label="公司类型：">
              <el-select
                v-model="listQuery.companyType"
                placeholder="请选择公司类型"
                filterable
                clearable
                class="w-140"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="随手记 ：">
              <el-input v-model="listQuery.jotDown" style="margin-left: 5px;"  class="w-140" size="small" placeholder="请输入随手记" />
            </el-form-item>
            <div v-if="checkType != 'book'">
              <el-form-item label="申报状态：">
                <el-radio-group v-model="listQuery.declaredStatus">
                  <el-radio :label="9">未申报</el-radio>
                  <el-radio :label="1">进行中</el-radio>
                  <el-radio :label="2">已完成</el-radio>
                  <el-radio :label="3">无需申报</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="扣款状态：">
                <el-radio-group v-model="listQuery.deductionStatus">
                  <el-radio :label="9">未扣款</el-radio>
                  <el-radio :label="1">进行中</el-radio>
                  <el-radio :label="2">已完成</el-radio>
                  <el-radio :label="3">无需扣款</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="检查状态：">
                <el-radio-group v-model="listQuery.checkStatus">
                  <el-radio :label="9">未检查</el-radio>
                  <el-radio :label="1">进行中</el-radio>
                  <el-radio :label="2">已完成</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <el-form-item label="记账状态：" v-else>
              <el-radio-group v-model="listQuery.inputStatus">
                <el-radio :label="9">未开始</el-radio>
                <el-radio :label="1">进行中</el-radio>
                <el-radio :label="2">已完成</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <el-table
        stripe
        :data="list"
        :height="contentStyleObj"
        border
        @selection-change="handleSelectionChange"
        v-loading="loading"
        @sort-change="sortChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column
          label="编号"
          align="center"
          width="80"
          fixed="left"
          prop="sort"
          sortable="custom"
        >
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="id" />
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="name"
          label="公司名称"
          min-width="240"
          fixed="left"
        >
          <template #default="scope">
            <TagNameCopy :row="scope.row" :showAuthIcon="false"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column align="center" label="税局" width="75">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pzCount"
          label="凭证数量"
          v-if="checkType == 'book'"
        >
        </el-table-column>
        <el-table-column
          prop="pzCount"
          label="凭证审核"
          v-if="checkType == 'book'"
        >
        </el-table-column>
        <el-table-column
          prop="pzCount"
          label="结账状态"
          v-if="checkType == 'book'"
        >
          <template #default="scope">
            <div class="item_icon">
              <i :class="settleStatusIcon(scope.row.settleStatus)"></i>
              <span>{{ settleStatusfilter(scope.row.settleStatus) }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="申报状态" v-if="checkType == 'tax'">
          <template #default="scope">
            <div class="item_icon">
              <i :class="settleTaxIcon(scope.row.declaredStatus)"></i>
              <span>{{
                settleTaxfilter(scope.row.declaredStatus, "申报")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="扣款状态" v-if="checkType == 'tax'">
          <template #default="scope">
            <div class="item_icon">
              <i :class="settleTaxIcon(scope.row.deductionStatus)"></i>
              <span>{{
                settleTaxfilter(scope.row.deductionStatus, "扣款")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="检查状态" v-if="checkType == 'tax'">
          <template #default="scope">
            <div class="item_icon">
              <i :class="settleTaxIcon(scope.row.checkStatus)"></i>
              <span>{{ settleTaxfilter(scope.row.checkStatus, "检查") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="随手记"
          min-width="150"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span
              class="xgmStyle"
              style="cursor: pointer"
              @click="smallNotes(scope.row)"
              >记</span
            >
            <span>{{ scope.row.jotDown }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </div>
    <jotDown ref="jotDownRef" @jotDownUpdate="updateNote" />
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import { getIndexAccounting } from "@/api/home";
import addressList from "@/components/Screening/address";
import jotDown from "@/components/jotDown";
import { COMPANY_TYPE_OPTION } from "@/utils/commonData.js";
const { proxy } = getCurrentInstance();
const props = defineProps({
  period: { type: String, default: "" },
  fromType: { type: String, default: "" },
  changeHeight: { type: Boolean, default: false },
  taxType: { type: String, default: "" },
  userId: { type: Array, default: () => [] },
});
const listQuery = ref({
  name: "",
  page: 1,
  limit: 20,
  inAllStatus: [],
  outAllStatus: [],
  accountingType: 0,
  address: "",
  jzStatus: 0,
  companyType: "",
  period: "",
  jotDown:'',
});
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});
const loading = ref(false);
const checkType = ref("");
const jzTotal = ref({});
const sbTotal = ref({});
const kkTotal = ref({});
const jcTotal = ref({});
const typeOptions = COMPANY_TYPE_OPTION;
const jotDownRef = ref();
watch(
  () => [props.period, props.taxType, props.userId],
  (newVal) => {
    listQuery.value.period = newVal[0];
    listQuery.value.type = newVal[1];
    listQuery.value.userId = newVal[2];
    getList();
  },
  { immediate: true }
);
watch(
  () => props.fromType,
  (newType) => {
    checkType.value = newType;
  },
  { immediate: true }
);
watch(
  () => props.changeHeight,
  (newVal) => {
    if (newVal) {
      contentStyleObj.value = proxy.$getHeight(490);
    } else {
      contentStyleObj.value = proxy.$getHeight(350);
    }
  }
);
onMounted(() => {
  contentStyleObj.value = props.changeHeight
    ? proxy.$getHeight(490)
    : proxy.$getHeight(350);
});
function getList() {
  loading.value = true;
  getIndexAccounting(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total;
      jzTotal.value = res.data.data.jzzt;
      sbTotal.value = res.data.data.sbzt;
      kkTotal.value = res.data.data.kkzt;
      jcTotal.value = res.data.data.jczt;
    }
  });
}
const smallNotes = (row) => {
  jotDownRef.value.init(row.name, row.id, listQuery.value.period);
};
const updateNote = (id, text) => {
  list.value.map((v) => {
    if (v.id == id) {
      v.jotDown = text;
    }
  });
};
const settleStatusfilter = (type) => {
  if (type == "2") {
    return "结账失败";
  } else if (type == "4") {
    return "已结账";
  } else {
    return "未结账";
  }
};
const settleStatusIcon = (type) => {
  if (type == "2") {
    return "iconfont icon-cuowu";
  } else if (type == "4") {
    return "iconfont icon-duihao";
  } else {
    return "iconfont icon-gantanhao";
  }
};
const settleTaxfilter = (type, text) => {
  if (type == 9) {
    return "未" + text;
  } else if (type == 1) {
    return "进行中";
  } else if (type == 2) {
    return "已完成";
  } else if (type == 3) {
    return "无需" + text;
  }
};
const settleTaxIcon = (type) => {
  if (type == 9) {
    return "iconfont icon-gantanhao";
  } else if (type == 1) {
    return "iconfont icon-info";
  } else if (type == 2 || type == 3) {
    return "iconfont icon-duihao";
  }
};
// 表格排序
const sortChange = (data) => {
  if (data.column.label == "编号") {
    if (data.order == "descending") {
      listQuery.value.desc = 1;
    } else {
      listQuery.value.desc = 0;
    }
    getList();
  }
};
const cancel = () => {
  let originLimit = listQuery.value.limit;
  listQuery.value = {
    name: "",
    page: 1,
    limit: originLimit,
    inAllStatus: [],
    outAllStatus: [],
    accountingType: 0,
    address: "",
    jzStatus: 0,
    companyType: "",
    period: props.period,
    type: props.taxType,
    userId: props.userId,
    jotDown:'',
  };
  getList();
};
</script>

<style scoped lang="scss">
.top_data {
  width: 100%;
  background-color: #edf8fa;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .data_count {
    color: #17a2b8;
    margin: 0 5px;
  }
  & > div {
    width: 33%;
    border-right: 1px solid #dbddde;
    text-align: center;
  }
  div:last-child {
    border-right: none;
  }
  .data_title {
    margin-right: 20px;
    position: relative;
  }
  .data_title::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--themeColor, #17a2b8);
  }
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.item_icon {
  cursor: pointer;
  i {
    font-size: 13px;
    margin-right: 4px;
  }
  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
</style>
